<template>
	<div class="card bg-gray-100 dash_card card-custom card-stretch">
		<div class="card-body p-0">
			<div class="cardtopbar">
				<!-- <div
					class="float-right custom-menu-select py-1 btn btn-success btn-shadow-hover"
					> -->
				<v-layout>
					<v-icon class="card-drag-handle">mdi-drag</v-icon>
					<h4>CUSTOMERS</h4>
					<v-spacer></v-spacer>
					<v-menu left offset-y :max-width="200">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								height="35"
								min-height="35"
								depressed
								color="success white--text"
								v-bind="attrs"
								v-on="on"
							>
								<span class="fs-16 text-capitalize px-5">{{ customerDuration.text }}</span>
								<v-icon>mdi-menu-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item
								v-for="(item, index) in durationList"
								:key="index"
								@click="customerDuration = item"
								:class="[
									'py-1',
									{
										'grey lighten-2': customerDuration.value == item.value,
									},
								]"
							>
								<v-list-item-title>{{ item.text }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-layout>
				<span class="text-black mt-3 font-weight-400 font-size-sm"
					>You have
					<template v-if="customerCountLoading">
						<v-progress-circular indeterminate size="14" width="2"></v-progress-circular>
					</template>
					<template v-else>
						{{ customerCount.count }}
					</template>
					new customers {{ lowerCase(customerDuration.text) }}</span
				>
			</div>
			<div id="kt_stats_widget_11_chart" class="card-rounded-bottom mid_part" data-color="danger">
				<div class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1">
					<span class="symbol symbol-circle symbol-50 symbol-light-danger mr-2">
						<span class="symbol-label">
							<span class="svg-icon svg-icon-xl svg-icon-success">
								<inline-svg :src="$assetURL('media/custom-svg/men.svg')" />
							</span>
						</span>
					</span>
					<div class="d-flex flex-column text-right">
						<span class="qnt_val font-weight-bolder text-success">
							<template v-if="customerCountLoading">
								<v-progress-circular indeterminate color="success"></v-progress-circular>
							</template>
							<template v-else>{{ customerCount.percentage }} %</template>
						</span>
						<span class="text-muted font-weight-bold mt-2"
							>more than {{ replace(lowerCase(customerDuration.text), "this", "last") }}</span
						>
					</div>
				</div>
				<div class="active_mem">
					<ul>
						<li>
							All Customers
							<span class="bg-light-warning">
								<template v-if="customerCountLoading">
									<v-progress-circular indeterminate size="24" color="warning"></v-progress-circular>
								</template>
								<template v-else>
									{{ customerCount.all_customers }}
								</template>
							</span>
						</li>
						<li>
							Active Customers
							<span class="bg-light-success"
								><template v-if="customerCountLoading">
									<v-progress-circular indeterminate size="24" color="success"></v-progress-circular>
								</template>
								<template v-else>
									{{ customerCount.active_customers }}
								</template></span
							>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { lowerCase, replace } from "lodash";
export default {
	name: "LeaveCount",
	data() {
		return {
			customerCountLoading: false,
			customerDuration: {
				text: "This Week",
				value: "this_week",
			},
			durationList: [
				{
					text: "This Week",
					value: "this_week",
				},
				{
					text: "This Month",
					value: "this_month",
				},
				{
					text: "This Year",
					value: "this_year",
				},
			],
			customerCount: {
				all_customers: 0,
				active_customers: 0,
				count: 0,
				percentage: 0,
			},
		};
	},
	methods: {
		lowerCase(param) {
			return lowerCase(param);
		},
		replace(param) {
			return replace(param);
		},
	},
};
</script>
